import hash from "object-hash";
import uuidv4 from "uuid/v4";
import { isObject } from "lodash";

import {
    CartProduct,
    CartProductHashObject,
    DISCOUNT_TYPE,
    MenuProductHashValues,
    OPEN_PRODUCT_TYPE,
    CustomerMeta,
    Addon,
    CustomerMetaStatus
} from "Types";
import { deepOmitTypename, omitUndefinedOrNullPropsFromObject, orderPropsInObjectAlphabetically } from "Utils";

/**
 * [FUNCTION] - Cart Product HashId - we have too many that use different hash
 * Making sure that we only use one function
 * If you need to add more to the hash value then try to only do it inside the function!!
 * @example createCartProductHashId(cartProduct, orderWindowId, null, false) // gets normal hash id
 * @example createCartProductHashId(cartProduct, orderWindowId) // gets discounts hash id as discount is applied
 * @example createCartProductHashId(cartProduct, orderWindowId, { type: DISCOUNT_TYPE.APPLIED_DISCOUNT }) // checks discounts hash id
 * @type {CartProduct} cart product type
 * @type {MenuProductHashValues} when using the menu product or bundle product to create a hash
 * @param productHashValues - see above types
 * @param orderWindowId - current order window id
 * @param hashValueObject - Use Cart product hash object
 * @param checkHashValueObject - Flag to tell function if to check addtional hash values
 * @returns {string} Hash Id
 */
export const createCartProductHashId = (
    productHashValues: CartProduct | MenuProductHashValues,
    orderWindowId: string,
    hashValueObject?: CartProductHashObject | null | undefined,
    checkHashValueObject: boolean = true
) => {
    /** Deconstruct the object - can be either the cart or MenuProductHashValue */
    const { orderProduct, menuProduct = null, menuBundleProduct = null, customerMeta = null } = productHashValues || {};
    const {
        selectedBundleProductItems = [],
        comment,
        addons = [],
        modifications = null,
        isOpenProduct = false
    } = orderProduct || {};

    /** Clean addons for hash id - this removes any __typename */
    const useAddonsOrNull = addons?.length ? (cleanObjectForHashId(addons) as Addon[]) : null;

    /** Normalise the comment for hash id */
    const normalisedComment = comment != null ? comment : "";

    /** Tell hash to check for additonal data - if not use null (good for testing / splitting the cart by discount)
     */
    const hashValueType = checkHashValueObject ? getHashValueObject(productHashValues, hashValueObject) : null;

    /** Customer Meta object */
    const customerMetaSortProps = customerMetaObject(customerMeta);

    if (!!useAddonsOrNull && useAddonsOrNull?.length > 1) {
        /** Sort addons by name asc
         * not doing this can cause differences in in the hash value
         * this is here to make sure that doesn't happen
         */
        useAddonsOrNull.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (menuProduct) {
        /** Clean modifications for hash id - this removes any __typename (Returned in parked in table) */
        const modificationsCleaned = modifications ? cleanObjectForHashId(modifications) : null;

        /** Here we check if a product is for free
         * We have had instances when 2 of the same product in subscriptions with 1 = free and a 2nd = 10% off
         * It will merge them together and miss the discounts
         */
        const subscriptionPercentage = menuProduct?.subscriptionProductMeta?.percentageDiscount ?? 0;
        const isMenuProductFree = subscriptionPercentage === 100;

        /** Remove nulls etc..
         * NOTE: there is a difference in the modifications when you park or Table an order and reload the cart
         * this is here to make sure that it doesn't change between all states
         */
        const modificationsToHash = !!modificationsCleaned
            ? omitUndefinedOrNullPropsFromObject(modificationsCleaned)
            : {};

        /** We only add the isFree for subscriptions when free otherwise we don't add the extra hash value */
        return hash({
            id: menuProduct?.id,
            modifications: modificationsToHash,
            normalisedComment,
            useAddonsOrNull,
            hashValueType,
            orderWindowId,
            customerMetaSortProps,
            isOpenProduct,
            ...(isMenuProductFree && { isFree: isMenuProductFree })
        });
    } else if (menuBundleProduct) {
        /** Clean bundle items for hash id - this removes any __typename (Returned in parked in table) */
        const bundleItems = cleanObjectForHashId(selectedBundleProductItems);

        /** Here we check if a product is for free
         * We have had instances when 2 of the same product in subscriptions with 1 = free and a 2nd = 10% off
         * It will merge them together and miss the discounts
         */
        const subscriptionPercentage = menuBundleProduct?.subscriptionProductMeta?.percentageDiscount ?? 0;
   
        const isMenuBundleProductFree = subscriptionPercentage === 100;

        /** We only add the isFree for subscriptions when free otherwise we don't add the extra hash value */
        return hash({
            id: menuBundleProduct.id,
            bundleItems,
            normalisedComment,
            useAddonsOrNull,
            hashValueType,
            orderWindowId,
            customerMetaSortProps,
            isOpenProduct,
            ...(isMenuBundleProductFree && { isFree: isMenuBundleProductFree })
        });
    }
    return uuidv4();
};

/**
 * [FUNCTION] removal + order props in the customer meta object
 * *** This is from group ordering
 * @param customerMeta
 * @returns
 */
const customerMetaObject = (customerMeta?: Partial<CustomerMeta> | null) => {
    /** If there is no customer meta (which can happen on parked order or table) it will be this object */
    if (!customerMeta) {
        return { status: CustomerMetaStatus.ADDED };
    }
    /** Order the type props */
    const customerMetaSortProps = orderPropsInObjectAlphabetically<Partial<CustomerMeta>>(customerMeta);

    /** Return the object without null or undefined props + values */
    return omitUndefinedOrNullPropsFromObject(customerMetaSortProps);
};

/**
 * [FUNCTION] this is for the additional type pased to the main function
 * @param cartProduct
 * @param hashValue
 * @returns
 */
const getHashValueObject = (
    cartProduct: CartProduct | MenuProductHashValues,
    hashValue?: CartProductHashObject | null | undefined
): CartProductHashObject | null => {
    /** If hash creation has the object passed in then return it */
    if (!!hashValue) {
        return hashValue;
    }

    /** If it is an open product - add hash object with unit price */
    if (!!cartProduct.orderProduct && cartProduct.orderProduct?.isOpenProduct === true) {
        return {
            type: OPEN_PRODUCT_TYPE.OPEN_PRODUCT,
            unitPrice: cartProduct.orderProduct.totalPrice
        };
    }

    /** If it has a discount and no hashValue object */
    if (!!cartProduct.orderProduct?.combinedDiscounts && cartProduct.orderProduct.combinedDiscounts.length > 0) {
        return {
            type: DISCOUNT_TYPE.APPLIED_DISCOUNT
        };
    }

    /** If none of the above then return null */
    return null;
};

/**
 * Function to clean values before hitting hash id
 * removes __typename and modificationsToUse
 * @param {any} valuesToCheck - could be a function or an object
 * @returns
 */
export const cleanObjectForHashId = (valuesToCheck: any) => {
    if (Array.isArray(valuesToCheck)) {
        return valuesToCheck.map(({ __typename, modificationsToUse, ...rest }) => {
            if (rest.addons || !!rest.modifications) {
                /** There could be modifications on the addons as well (this was missing) */
                const modifications = !!rest.modifications ? deepOmitTypename(rest.modifications) : {};
                return {
                    ...rest,
                    addons: rest?.addons?.map(({ __typename, ...restAddons }: any) => {
                        return restAddons;
                    }),
                    modifications: !!modifications ? omitUndefinedOrNullPropsFromObject(modifications) : modifications
                };
            }
            return rest;
        });
    }

    if (isObject(valuesToCheck)) {
        const { modificationsToUse, ...removeTypeName } = deepOmitTypename(valuesToCheck);
        return removeTypeName;
    }

    return valuesToCheck;
};

/**
 * [FUNCTION] Simple hashId function * DO NOT use for cartProduct id!!!
 * @param {string[]} values - string array
 */
export const createSimpleHashId = (values: string[]) => {
    return hash({ id: values.join() });
};


export const isValidUUID = (id: string) => {
    const uuidValidator = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidValidator.test(id);
};
